import { QuoteDetails } from '../store/quoteSlice';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface ConfirmReservationRequest {
  locationCode: string;
  unitName: string;
  unitType: string;
  estMoveIn: string;
  globalWaitingNum: string;
  firstPayment: number;
  regularPayment: number;
  details: QuoteDetails;
}

export interface ShowPricesRequest {
  locationCode: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  estMoveIn: string;
}

export const backendApi = createApi({
  reducerPath: 'backendApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  endpoints: (build) => ({
    confirmReservation: build.mutation<
      { success: boolean },
      ConfirmReservationRequest
    >({
      query: (body) => ({
        url: 'confirm-reservation',
        method: 'POST',
        body,
      }),
    }),
    showPrices: build.mutation<{ success: boolean }, ShowPricesRequest>({
      query: (body) => ({
        url: 'show-prices',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const useConfirmReservationMutation =
  backendApi.endpoints.confirmReservation.useMutation;
export const useShowPricesMutation =
  backendApi.endpoints.showPrices.useMutation;
