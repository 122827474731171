import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import validate from 'validate.js';
import { RootState } from './index';
import UnitType from '../entities/UnitType';
import { selectLocations } from './locationSlice';
import { selectDescriptors, selectUnitType } from './unitListSlice';
import { isDesignTestEnabled } from 'src/config';

export interface QuoteStep {
  id: number;
  name: string;
  url: string;
  active: boolean;
  valid: boolean;
}

export interface QuoteDetails {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  termsAndConditions: boolean;
}

export interface QuoteState {
  token: string | null;
  location: string | null;
  descriptor: string | null;
  unitType: string | null;
  estMoveIn: string | null;
  postalCode: string | null;
  globalWaitingNum: string | null;
  waitingId: string | null;
  tenantId: string | null;
  details: QuoteDetails;
  steps: QuoteStep[];
  loading: boolean;
  insuranceId: string | null;
  insuranceCoverage: number | null;
}

const STEPS: Pick<QuoteStep, 'id' | 'name' | 'url'>[] = [
  {
    id: 1,
    name: isDesignTestEnabled() ? 'Location' : 'Store',
    url: '/',
  },
  {
    id: 2,
    name: isDesignTestEnabled() ? 'Size' : 'Unit',
    url: '/unit',
  },
  {
    id: 3,
    name: isDesignTestEnabled() ? 'Details' : 'Info',
    url: '/info',
  },
  {
    id: 4,
    name: isDesignTestEnabled() ? 'Quote' : 'Price',
    url: '/price',
  },
];

const initialState: QuoteState = {
  token: null,
  insuranceId: null,
  insuranceCoverage: null,
  location: null,
  descriptor: null,
  unitType: null,
  estMoveIn: null,
  postalCode: null,
  globalWaitingNum: null,
  waitingId: null,
  tenantId: null,
  details: {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    termsAndConditions: false,
  },
  steps: STEPS.map((step) => ({
    ...step,
    active: false,
    valid: false,
  })),
  loading: true,
};

const detailConstraints = {
  firstName: { presence: { allowEmpty: false } },
  lastName: { presence: { allowEmpty: false } },
  email: { presence: { allowEmpty: false }, email: true },
  phone: { presence: { allowEmpty: false } },
  termsAndConditions: (val: unknown) => val === true,
};

const checkStepValid = (step: QuoteStep, state: RootState): boolean => {
  switch (step.id) {
    case 1:
      return !!state.quote.location;
    case 2:
      return !!state.quote.descriptor;
    case 3:
      return validate(state.quote.details, detailConstraints) === undefined;
    case 4:
      return false;
    default:
      return false;
  }
};

export const setQuoteStep = createAsyncThunk(
  'quote/setQuoteStep',
  (stepId: number, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;

    const steps = state.quote.steps.map((s) => ({
      ...s,
      valid: checkStepValid(s, state),
    }));

    const isValid = steps.slice(0, stepId - 1).every((s) => s.valid);

    steps.forEach((s) =>
      dispatch(setQuoteStepValid({ step: s.id, valid: s.valid }))
    );

    if (!isValid) {
      const back = steps.slice(0, stepId - 1).reduce((acc, curr) => {
        return curr.valid ? curr.id : acc;
      }, -1);

      return rejectWithValue(back);
    }

    dispatch(setQuoteStepActive(stepId));

    return stepId;
  }
);

export const validateStep = createAsyncThunk(
  'quote/validateStep',
  (stepId: number, { getState, dispatch }) => {
    const state = getState() as RootState;

    const step = state.quote.steps.find((s) => s.id === stepId);

    if (!step) {
      return;
    }

    dispatch(
      setQuoteStepValid({
        step: step.id,
        valid: checkStepValid(step, state),
      })
    );
  }
);

type QuoteDetailPayload<T extends keyof QuoteDetails> = PayloadAction<{
  field: T;
  value: QuoteDetails[T];
}>;

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    resetQuote: () => {
      return {
        ...initialState,
      };
    },
    setQuoteToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setQuoteInsuranceId: (state, action: PayloadAction<string | null>) => {
      state.insuranceId = action.payload;
    },
    setQuoteInsuranceCoverage: (state, action: PayloadAction<number | null>) => {
      state.insuranceCoverage = action.payload;
    },
    setQuoteLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setQuoteLocation: (state, action: PayloadAction<string | null>) => {
      state.location = action.payload;
      state.descriptor = null;
      state.unitType = null;
    },
    setQuoteDescriptor: (state, action: PayloadAction<string | null>) => {
      state.descriptor = action.payload;
    },
    setQuoteUnitType: (state, action: PayloadAction<string | null>) => {
      state.unitType = action.payload;
    },
    setQuoteEstMoveIn: (state, action: PayloadAction<Date | null>) => {
      state.estMoveIn = action.payload ? action.payload.toISOString() : null;
    },
    setQuotePostalCode: (state, action: PayloadAction<string | null>) => {
      state.postalCode = action.payload;
    },
    setQuoteGlobalWaitingNum: (state, action: PayloadAction<string | null>) => {
      state.globalWaitingNum = action.payload;
    },
    setQuoteWaitingId: (state, action: PayloadAction<string | null>) => {
      state.waitingId = action.payload;
    },
    setQuoteTenantId: (state, action: PayloadAction<string | null>) => {
      state.tenantId = action.payload;
    },
    setQuoteStepValid: (
      state,
      action: PayloadAction<{ step: number; valid: boolean }>
    ) => {
      const { step, valid } = action.payload;

      state.steps[step - 1].valid = valid;
    },
    setQuoteStepActive: (state, action: PayloadAction<number>) => {
      const steps = [...state.steps].map((step) => ({
        ...step,
        active: action.payload === step.id,
      }));

      state.steps = [...steps];
    },
    setQuoteDetailsField: <T extends keyof QuoteDetails>(
      state: QuoteState,
      action: QuoteDetailPayload<T>
    ) => {
      const { field, value } = action.payload;
      state.details[field] = value;
    },
  },
});

export const {
  resetQuote,
  setQuoteToken,
  setQuoteInsuranceId,
  setQuoteInsuranceCoverage,
  setQuoteLoading,
  setQuoteLocation,
  setQuoteDescriptor,
  setQuoteUnitType,
  setQuoteEstMoveIn,
  setQuotePostalCode,
  setQuoteWaitingId,
  setQuoteTenantId,
  setQuoteGlobalWaitingNum,
  setQuoteStepValid,
  setQuoteStepActive,
  setQuoteDetailsField,
} = quoteSlice.actions;

export const selectQuote = (state: RootState) => state.quote;
// Token used for patching reservation with insurance, deal data
export const selectQuoteToken = (state: RootState) => state.quote.token;
export const selectQuoteInsuranceId = (state: RootState) => state.quote.insuranceId;
export const selectQuoteInsuranceCoverage = (state: RootState) => state.quote.insuranceCoverage;
// Tracking progress through the form
export const selectQuoteSteps = (state: RootState) => state.quote.steps;

export const selectQuoteStep = (step: number) => (state: RootState) =>
  state.quote.steps[step - 1];

export const selectActiveQuoteStep = (state: RootState) =>
  state.quote.steps.find((step) => step.active);

export const selectQuoteLoading = (state: RootState) => state.quote.loading;

// Tenant data
export const selectQuoteDetails = (state: RootState) => state.quote.details;

// Return the currently selected [x]
export const selectQuoteLocation = createSelector(
  (state: RootState) => state.quote.location,
  selectLocations,
  (quoteLocation, locations) =>
    locations.find((l) => l.locationCode === quoteLocation) ?? null
);

export const selectQuoteDescriptor = createSelector(
  (state: RootState) => state.quote.descriptor,
  (state: RootState) => selectDescriptors(state, state.quote.location ?? ''),
  (quoteDescriptor, descriptors) =>
    descriptors.find((d) => d._id === quoteDescriptor) ?? null
);

// export const selectQuoteLocation = (state: RootState) =>
//   state.quote.location ? selectLocation(state.quote.location)(state) : null;

// export const selectQuoteDescriptor = (state: RootState) =>
//   state.quote.descriptor
//     ? selectDescriptor(state.quote.location, state.quote.descriptor)(state)
//     : null;

export const selectQuoteUnitType = (state: RootState) =>
  state.quote.unitType
    ? selectUnitType(state.quote.location, state.quote.unitType)(state)
    : null;

export const selectQuoteEstMoveIn = (state: RootState) => state.quote.estMoveIn;

export const selectQuotePostalCode = (state: RootState) =>
  state.quote.postalCode;

export const selectQuoteGlobalWaitingNum = (state: RootState) =>
  state.quote.globalWaitingNum;

export const selectQuoteWaitingId = (state: RootState) => state.quote.waitingId;
export const selectQuoteTenantId = (state: RootState) => state.quote.tenantId;

type UnitTypesByLevel = {
  [floor: string]: UnitType[];
};

// Return all available [x]
export const selectSortedUnitTypes = (state: RootState) => {
  const location = selectQuoteLocation(state);
  const unitTypes = selectQuoteDescriptor(state)?.unitTypes;

  if (!location || !unitTypes) {
    return undefined;
  }

  let unitTypesByLevel: UnitTypesByLevel = {};

  // Start by grouping the unit types into ground floor and upper floor (which is everything else).
  // If groupUnitsByFloor is false (unlikely), just chuck them all into one array.
  if (!location.settings.groupUnitsByFloor) {
    unitTypesByLevel = { '': unitTypes.slice() };
  } else {
    unitTypesByLevel = unitTypes.reduce(
      (unitTypes: UnitTypesByLevel, unitType: UnitType) => {
        const floor =
          unitType.floor === location.settings.groundFloor
            ? 'Ground Floor'
            : 'Upper Floor - Lift Access';
        if (!unitTypes[floor]) {
          unitTypes[floor] = [];
        }
        unitTypes[floor].push(unitType);
        return unitTypes;
      },
      unitTypesByLevel
    );
  }

  // There are four attributes by which units can be sorted in RapidStor (plus 'none').
  // Creating sorting functions for each.
  const sorters: {
    [RapidStorUnitSortOrder: string]: (a: UnitType, b: UnitType) => number;
  } = {
    none: () => 0,
    floor: (a, b) => b.floor - a.floor,
    size: (a, b) => b.area - a.area,
    availability: (a, b) => b.totalVacant - a.totalVacant,
    price: (a, b) => b.rates.discountedRate - a.rates.discountedRate,
  };

  // Sort both the upper and ground floor units
  return Object.entries(unitTypesByLevel)
    .map(([floor, unitTypes]) => {
      unitTypes.sort((a, b) => {
        // Compare each attribute in order of priority.
        // If the attributes of units a and b are equal (result == 0), continue to the next attribute.
        for (const sortOrder of location.settings.unitSortOrder) {
          const result = sorters[sortOrder](a, b);
          if (result) {
            return result;
          }
        }
        return 0;
      });

      // We only display the first available / or first unit
      return { floor, unitType: unitTypes.find(t => t.hasComputedAvailable) ?? unitTypes[0]};
    })
    .sort((a, b) => {
      // Finally, sort the floor groups themselves to put the most expensive one first
      return b.unitType.rates.discountedRate - a.unitType.rates.discountedRate;
    });
};

export default quoteSlice.reducer;
